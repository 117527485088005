export default [
    {
        path: "/dashboard/users/:kind",
        name: "users",
        meta: {
            relation: "users"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Users/Overview.vue")
        },
        children: [
            {
                path: "/dashboard/users/:kind/system-payout",
                name: "system-payout-report",
                meta: {
                    relation: "users"
                },
                components: {
                    system_payout_report: () => import("@/views/Dashboard/Users/PayoutReport.vue")
                }
            },
            {
                path: "/dashboard/users/:kind/generate-payout",
                name: "user-generate-payout",
                meta: {
                    relation: "users"
                },
                components: {
                    user_generate_payout: () => import("@/views/Dashboard/Users/GeneratePayout.vue")
                }
            },
            {
                path: "/dashboard/users/:kind/create",
                name: "create-user",
                meta: {
                    relation: "users"
                },
                components: {
                    create_user: () => import("@/views/Dashboard/Users/CreateUser.vue")
                }
            },
            {
                path: "/dashboard/users/:kind/edit/:userId",
                name: "edit-user",
                meta: {
                    relation: "users"
                },
                components: {
                    edit_user: () => import("@/views/Dashboard/Users/EditUser.vue")
                }
            },
            {
                path: "/dashboard/users/:kind/reset/:userId",
                name: "reset-user",
                meta: {
                    relation: "users"
                },
                components: {
                    edit_user: () => import("@/views/Dashboard/Users/ResetUser.vue")
                }
            }
        ]
    },
];
