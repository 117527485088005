export default [
    {
        path: "/dashboard/module-overview",
        name: "module-overview",
        meta: {
            relation: "module",
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Module/Overview.vue"),
        },
        children: [
            {
                path: "/dashboard/module-overview/create",
                name: "create-module",
                meta: {
                    relation: "module",
                },
                components: {
                    create_module: () => import("@/views/Dashboard/Module/CreateModule.vue"),
                },
            }
        ],
    },
    {
        path: 'bundle/:monetization_bundle_id/module-preview/:module_id',
        name: 'bundle-module-preview',
        meta: {
          relation: "module",
        },
        components: {
          dashboard: () => import("@/views/Account/Bundles/Modules/Module.vue"),
        },
      },
    {
        path: "/dashboard/module/:id",
        name: "module",
        meta: {
            relation: "module",
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Module/Main.vue"),
        },
        children: [
            {
                path: "/dashboard/module/:id/editor",
                name: "module-editor",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/Editor/Editor.vue"),
                },
                children: [
                    {
                        path: "/dashboard/module/:id/editor/create-sub-module",
                        name: "create-sub-module",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            create_sub_module: () => import("@/views/Dashboard/Module/CreateSubModule/Main.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/editor/create-card",
                        name: "create-card",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            create_card: () => import("@/views/Dashboard/Common/CreateEntity.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/editor/create-definition",
                        name: "create-definition",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            create_definition: () => import("@/views/Dashboard/Common/CreateEntity.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/editor/history",
                        name: "module-editor-history",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            history: () => import("@/views/Dashboard/Module/Editor/History.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/editor/request",
                        name: "module-editor-request",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            request: () => import("@/views/Dashboard/Module/Editor/Request.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/editor/review/notes",
                        name: "module-content-notes",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            content_notes: () => import("@/views/Dashboard/Module/ContentNotes.vue"),
                        }
                    }
                ],
            },
            {
                path: "/dashboard/module/:id/sub-content",
                name: "module-sub-content",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/SubContent.vue"),
                },
            },
            {
                path: "/dashboard/module/:id/exam",
                name: "module-exam-editor",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/ExamEditor.vue"),
                }
            },
            {
                path: "/dashboard/module/:id/authors",
                name: "module-authors",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/Authors.vue"),
                },
                children: [
                    {
                        path: "/dashboard/module/:id/authors/request-author",
                        name: "request-author",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            request_author: () => import("@/views/Dashboard/Module/RequestAuthor.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/authors/add-author",
                        name: "add-author",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            add_author: () => import("@/views/Dashboard/Module/AddAuthor.vue"),
                        },
                    },
                    {
                        path: "/dashboard/module/:id/authors/add-reviewer",
                        name: "add-reviewer",
                        meta: {
                            relation: "module",
                        },
                        components: {
                            add_author: () => import("@/views/Dashboard/Module/AddReviewer.vue"),
                        },
                    },
                ],
            },
            {
                path: "/dashboard/module/:id/media",
                name: "module-media",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/Media.vue"),
                },
            },
            {
                path: "/dashboard/module/:id/analytics",
                name: "module-analytics",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/Analytics.vue"),
                },
            },
            {
                path: "/dashboard/module/:id/settings",
                name: "module-settings",
                meta: {
                    relation: "module",
                },
                components: {
                    module_content: () => import("@/views/Dashboard/Module/Settings.vue"),
                },
            },
        ],
    },
];
