'use strict';

export default [
	{
		path: '/dashboard/media',
		name: 'media_overview',
		components: {
			dashboard: () => import('@/views/Dashboard/Media/MediaOverview.vue')
		},
		meta:{
			relation: 'media',
			help:'media_overview'
		},
		children: [
			{
				path: '/dashboard/media/create',
				name: 'create_media',
				meta:{
					relation: 'media',
				},
				components: {
					create_media: () => import(
						'@/views/Dashboard/Common/CreateEntity.vue'
					)
				}
			}
		]
	},
	{
        path: "/dashboard/media/:id",
        name: "media-container",
        meta: {
            relation: "media"
        },
        components: {
            dashboard: () => import("@/views/Dashboard/Media/Single/Container.vue")
        },
        children: [
            {
                path: "/dashboard/media/:id",
                name: "media-content",
                meta: {
                    relation: "media"
                },
                components: {
                    media_container: () => import("@/views/Dashboard/Media/Single/MediaContent.vue")
                }
            }
		]
	}
];