import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import {
    checkAuthentication,
    buildRouterTree
} from './auth';


// system 
import userAdministration from './system/user-administration'
// import dummyPages from './system/dummyPages';

// account modules
// import _client_module from './account/module';

// admin
import user from './admin/user';
import contract from './admin/contract';
import affiliate from './admin/affiliate';
import _module from './admin/module';
import card from './admin/card';
import monetizationBundle from './admin/monetization-bundle';
import settings from './admin/settings';
import messages from './admin/messages';
import menu from './admin/menu';
import media from './admin/media';
import pages from './admin/pages';
import bookmarks from './account/bookmarks';
import bundles from './account/bundles';
import modules from './account/module';
import profile from './account/profile';
import spaces from './admin/spaces';
import accountSpaces from './account/spaces';

const routes = [
    {
        path: '/',
        name: 'base',
        redirect: '/login'
    },
    ...userAdministration,
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/Client.vue'),
        meta: {
            restricted: true,
            role: 'account'
        },
        children: [
            {
                path: '/account/contracts',
                name: 'account-contracts-sign',
                meta: {
                    relation: 'account'
                },
                components: {
                    account_contract_sign: () => import('@/views/Dashboard/ContractsSign.vue')
                }
            },
            ...bookmarks,
            ...profile,
            ...bundles,
            ...modules,
            ...accountSpaces
        ]
    }, {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
            restricted: true,
            role: 'admin'
        },
        children: [
            {
                path: '/dashboard/test-page',
                name: 'components-example',
                components: {
                    dashboard: () => import('@/views/ComponentsTest.vue')
                }
            },
            {
                path: '/dashboard/contracts-sign',
                name: 'contracts-sign',
                meta: {
                    relation: 'dashboard'
                },
                components: {
                    dashboard: () => import('@/views/Dashboard/ContractsSign.vue')
                }
            },
            ...user,
            ...contract,
            ...affiliate,
            ..._module,
            ...card,
            ...monetizationBundle,
            ...settings,
            ...messages,
            ...menu,
            ...media,
            ...pages,
            ...spaces,
            {
                path: '/dashboard/profile',
                name: 'dashboard-profile',
                meta: {
                    relation: 'profile'
                },
                components: {
                    dashboard: () => import('@/views/Account/Profile.vue')
                }
            },
            {
                path: '/dashboard/message/:id/notes',
                name: 'dashboard-message-notes',
                meta: {
                    relation: 'dashboard'
                },
                components: {
                    dashboard_notes: () => import ('@/views/Dashboard/Messages/Single/Notes.vue')
                }
            },
        ]
    }, {
        path: '*',
        name: 'page-not-found',
        component: () => import('@/views/404')
    }
];

buildRouterTree(routes);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

checkAuthentication(router);

export default router