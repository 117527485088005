"use strict";

export default {
  // not loaded files

  hide_menu: "Hide menu",
  dashboard: "Dashboard",
  modules: "Modules",
  overview: "Overview",
  monetization_bundles: "Monetization Bundles",
  create_new_monetization_bundle: "Create new monetization bundle",
  user: "User",
  author_admin: "Author/Admin",
  accounts: "Accounts",
  contracts: "Contracts",
  messages: "Messages",
  settings: "Settings",
  archive: "Archive",
  profile: "Profile",
  web: "Web System",
  page: "Pages",
  menu: "Menus",
  media: "Media",

  seconds: "seconds",
  notification_dismiss_after: "This notification will dismiss after",
  profile_settings: "Profile settings",
  login: "Login",
  username: "Username",
  password: "Password",
  forgot_password: "Reset your password",
  back_to_login: "Back to the login",
  or: "or",
  register: "Register",
  first_name: "First name",
  last_name: "Last name",
  email: "Email",
  confirm_password: "Confirm password",
  send: "Send",
  send_message: "Send message",
  i_agree_to_the_privacy_policy: (link) =>
    `I agree with the <a href="${link}" target='_blank'>privacy policy</a>`,
  i_agree_with_the_terms_of_use: (link) =>
    `I agree with the <a href="${link}" target='_blank'>terms of use</a>`,
  i_would_like_to_subscribe_newsletter:
    "I would like to subscribe to a newsletter.",
  passHint:
    "Password length should be between 8 and 32. To make it stronger, use upper and lower case letters, numbers, and symbols like @ $ ! # % * ? &",
  passCorfirmHint: "Does not match password",
  emailHint: "Please enter valid email",
  firstNameHint: "Use only letters",
  lastNameHint: "Use only letters",
  required: "Required",
  forgot_page_description:
    "Enter the email address associated with your account, and we'll send you a link to reset your password.",
  enter_correct_email: "Enter the correct email",
  enter_the_module_name: "Enter the module name",
  enter_the_name: "Enter the name",
  your_email: "Your email",
  reset_password: "Reset password",
  hide_menu: "Hide menu",
  logout_error_user: "There has been an error during the logout process",
  menu: "Menu",
  new_menu: "Create new menu",
  no_data: "No data",
  nothing_found: "Nothing found!",
  title: "Title",
  tags: "Tags",
  description: "Description",
  page: "Page",
  pages: "Pages",
  new_page: "Create new page",
  save: "Save",
  revisions: "Revisions",
  reject: "Reject",
  reinstate: "Reinstate",
  pending_changes: "Pending Changes",
  object_has_been_updated: "Object has been updated.",
  nothing_to_update_here: "Nothing to update here",
  note: "Note",
  menu_title: "Menu title",
  menu_item: "Menu item",
  last_publication: "Last publication",
  last_change: "Last change",
  general_settings: "General settings",
  exit_revision_mode: "Exit Revision mode",
  exit_change_mode: "Exit Change mode",
  discard_changes: "Discard changes",
  changes: "Changes",
  are_you_sure_you_want_to_leave_the_page:
    "Are you sure that want to leave the page? All unsaved changes will be lost.",
  approve: "Approve",
  do_you_want_to_leave:
    "Do you really want to leave? you have unsaved changes!",
  show_more: "Show more",
  show_less: "Show less",
  go_back_to_homepage: "Go back to homepage",
  not_set: "Not set",
  media: "Media",
  overview: "overview",
  open: "Open",
  permission_error: "Permission error",
  search_error: "Search error",
  db_error:
    "Unknown database error. Please report to your comserve.io administrator.",
  title_not_found: "Title not found",
  add_media: "Add media",
  add_new_media: "Add a new media",
  enter_media_name: "Enter media name",
  enter_media_description: "Enter media description",
  upload_files: "Upload Files",
  media_library: "Media Library",
  select_media: "Select media",
  create: "Create",
  close: "Close",
  description_required: "Description is required",
  title_required: "Title is required",
  no_permission: "No permission",
  db_statics:
    "Unknown database error. Please report to your comserve.io administrator.",
  success: "Success",
  name_used: "The classification name is used",
  select_media_format:
    "Select apng, avif, gif, jpg, png, svg, webp, mp4, ogv, flv, m4v, webm, asf or avi to upload",
  title_already_used: "Title already used",
  name_required: "Name is required",
  text_required: "Text is required",
  parent_required: "Parent required",
  parent_not_found: "Product not found",
  no_properties: "No properties",
  no_classification_mounted:
    "The classification has no data group or has been broken. Please try another or contact the administrator of comserve.io.",
  unexpected_error:
    "Unexpected error. Please report to your comserve.io administrator.",
  no_media: "Media is required",
  error_uploading_file: "File upload error",
  selected_preset: "Selected preset",
  reset: "Reset",
  create_new_entity: (label) => `Create new ${label ? label : ""}`,
  enter_entity_name: "Enter entity name",
  enter_entity_text: "Enter entity text",
  prefix: "Prefix",
  edit: "Edit",
  popup_select_key_is_missed: "The popup-select key is missed",
  assignee_is_required: "Assignee is required",
  change: "Changes",
  revision: "Revisions",
  back_to_overview: "Back to overview",
  embed_link: "Embed/Link",
  updated: "Updated",
  created: "Created",
  published: "Published",
  tag_deleted_successfully: "Tag deleted successfully",
  payload_required: "Payload is required",
  gallery_with_label_should_not_be_empty: (label) =>
    `Gallery with label "${label}" should not be empty`,
  do_you_really_want_to_leave:
    "Do you really want to leave? you have unsaved changes!",
  create_new_report: "Create new report",
  reports: "Reports",
  add: "Add",
  by_user: "By user",
  browse_files: "Browse files",
  drag_and_drop_to_upload: (types) => `Drag & Drop: ${types}, to upload`,
  allowed_image_types: (types) => `Allowed image types: ${types}`,
  allowed_file_types: (types) => `Allowed file types: ${types}`,
  drag_and_drop_file_to_upload: "Drag & Drop file to upload",
  best_size: (size) => `Best size: ${size ? size : "1024px x 768px"}`,
  upload: "Upload",
  media_url: "Media url",
  browser_does_not_support_video:
    "Your browser does not support the video tag.",
  no_slots_available:
    "No slots avaialable for the page. Please consider adding slots in template attached to this page.",
  remove: "Remove",
  active_page: "Active page",
  template: "Template",
  templates: "Templates",
  are_you_sure_you_want_to_leave_page:
    "Are you sure that want to leave the page? All unsaved changes will be lost.",
  unknown_server_issue: "Unknown server issue",

  // src\views\Account\Bundles\Bundle.vue
  modules_overview: "Modules overview",
  // src\views\Account\Bundles\Bundle.vue END

  //src\views\Dashboard\Common\Settings.vue
  unable_to_save_entity_can_not_be_empty: () => "",
  //src\views\Dashboard\Common\Settings.vue END

  //src\views\Dashboard\Contract\Single\Container.vue  ///WORKS///
  save: "Save",
  essentiala_negotii: "Essential'a negotii",
  contractual_parties: "Contractual parties",
  contract_tiree: "Contract tiree",
  signatories: "Signatories",
  //src\views\Dashboard\Contract\Single\Container.vue END

  //src\views\Dashboard\Contract\Single\ContractContent.vue ///WORKS///
  contract_text: "Contract text",
  saved: "Saved",
  last_change: "Last change",
  created_by: "Created by",
  last_change_by: "Last change by",
  date_of_effect: "Date of effect",
  groups: "Groups",
  specify_date_of_effect_is_required: "Date of effect is required",
  //src\views\Dashboard\Contract\Single\ContractContent.vue END

  //src\views\Dashboard\Contract\Single\ContractSignatories.vue  ///WORKS///
  no_users: "No users",
  //src\views\Dashboard\Contract\Single\ContractSignatories.vue END

  //src\views\Dashboard\Contract\Single\ContractualParties.vue  ///WORKS///
  select: "Select",
  admin: "Admin",
  account: "Account",
  deselect: "Deselect",
  include: "Include",
  all: "All",
  admins: "Admins",
  include_all_admin_users_in_to_selection:
    "Include all admin users in to selection",
  include_admin_users_in_to_selection: "Include admin users in to selection",
  include_account_users_in_to_selection:
    "Include account users in to selection",
  include_all_account_users_in_to_selection:
    "Include all account users in to selection",
  accounts: "Accounts",
  no_admins: "No admins",
  //src\views\Dashboard\Contract\Single\ContractualParties.vue END

  //src\views\Dashboard\Contract\Overview.vue  ///WORKS///
  contract_overview: "Contract overview",
  create_new_contract: "Create new contract",
  title: "Title",
  text: "Text",
  //src\views\Dashboard\Contract\Overview.vue END

  //src\views\Dashboard\DashboardOverview\Main.vue  ///WORKS///
  my_modules: "My Modules",
  join_module: "Join Module",
  notes: "Notes",
  show_all: "Show all",
  //src\views\Dashboard\DashboardOverview\Main.vue END

  //src\views\Dashboard\Definition\Main.vue   ///WORKS///
  used_in: "Used in",
  display_title: "Display title",
  explanation_of_definition: "Explanation of definition",
  no_data: "No data",
  definition_title: "Definition title",
  do_you_really_want_to_leave_you_have_unsaved_changes:
    "Do you really want to leave? you have unsaved changes!",
  //src\views\Dashboard\Definition\Main.vue END

  //src\views\Dashboard\Media\Single\MediaContent.vue   ///WORKS///
  media_url: "Media url",
  upload: "Upload",
  browser_does_not_support_video:
    "Your browser does not support the video tag.",
  link_copied: "Link copied",
  //src\views\Dashboard\Media\Single\MediaContent.vue END

  //src\views\Dashboard\Media\MediaOverview.vue   ///WORKS///
  add_media: "Add media",
  add_new_media: "Add a new media",
  enter_media_name: "Enter media name",
  enter_media_description: "Enter media description",
  upload_files: "Upload Files",
  media_library: "Media Library",
  select_media: "Select media",
  open: "Open",
  //src\views\Dashboard\Media\MediaOverview.vue END

  //src\views\Dashboard\Menu\Overview\CreateNew.vue   ///WORKS///
  create_new_menu: "Create new menu",
  description: "Description",
  create: "Create",
  close: "Close",
  menu_added: "Menu added!",
  //src\views\Dashboard\Menu\Overview\CreateNew.vue END

  //src\views\Dashboard\Menu\Overview\Menu.vue   ///WORKS///
  //src\views\Dashboard\Menu\Overview\Menu.vue END

  //src\views\Dashboard\Menu\Single\GeneralSettings.vue   ///WORKS///
  menu: "Menu",
  path_name: "menu",
  //src\views\Dashboard\Menu\Single\GeneralSettings.vue END

  //src\views\Dashboard\Menu\Single\Menu.vue   ///WORKS///
  menu_title: "Menu title",
  general_settings: "General settings",
  object_has_been_updated: "Object has been updated.",
  menu_item: "Menu item",
  //src\views\Dashboard\Menu\Single\Menu.vue END

  //src\views\Dashboard\Messages\Single\Container.vue    ///WORKS///
  recipients: "Recipients",
  message: "Message",
  message_content: "Message content",
  //src\views\Dashboard\Messages\Single\Container.vue END

  //src\views\Dashboard\Messages\Single\Notes.vue   ///WORKS///
  //src\views\Dashboard\Messages\Single\Notes.vue END

  //src\views\Dashboard\Messages\Single\Recipients.vue   ///WORKS///
  //src\views\Dashboard\Messages\Single\Recipients.vue END

  //src\views\Dashboard\Messages\Single\Settings.vue   ///WORKS///
  created: "Created",
  hasNotes: "Has notes",
  //src\views\Dashboard\Messages\Single\Settings.vue END

  //src\views\Dashboard\Messages\Overview.vue  ///WORKS///
  messages_overview: "Messages overview",
  create_new_message: "Create new message",
  create_new: "Create new",
  //src\views\Dashboard\Messages\Overview.vue END

  //src\views\Dashboard\Module\CreateSubModule\Main.vue   ///WORKS///
  create_new_module: "Create new module",
  create_new_sub_module: "Create new sub module",
  required: "Required",
  excerpt: "Excerpt",
  free: "Free",
  tags: "Tags",
  authors: "Authors",
  cancel: "Cancel",
  select_content_type: "Select content type",
  back_to_basic_input: "Back to basic input",
  original: "Original",
  link: "Link",
  copy: "Copy",
  the_current_module_will_have_its_own_original_content:
    "The current module will have its own original content",
  the_current_module_will_have_a_link_to_the_content_of_another_module:
    "The current module will have a link to the content of another module",
  the_current_module_will_have_a_copy_of_the_content_of_another_module:
    "The current module will have a copy of the content of another module",
  monetization_bundle: "Monetization Bundle",
  detail: "Detail",
  content_used: "Content used",
  copied: "Copied",
  linked: "Linked",
  no_content_selected: "No content selected",
  //src\views\Dashboard\Module\CreateSubModule\Main.vue END

  // src\views\Dashboard\Module\AddReviewer.vue
  manage_reviewers: "Manage reviewers",
  send: "Send",
  user_name: "Username",
  add: "Add",
  remove_reviewer: "Remove",
  reviewer_removed: "Reviewer removed",
  linked_reviewers: "Linked reviewers",
  available_reviewers: "Available reviewers",

  // src\views\Dashboard\Module\AddReviewer.vue END

  // src\views\Dashboard\Module\Analytics.vue

  // (static demo content)

  // src\views\Dashboard\Module\Analytics.vue END

  // src\views\Dashboard\Module\Authors.vue

  write_here_to_search: "Write here to search",
  request_author: "Request author",
  add_author: "Add author",
  add_reviewer: "Add reviewer",
  unknown: "Unknown",

  // src\views\Dashboard\Module\Authors.vue END

  // src\views\Dashboard\Module\CreateModule.vue
  // src\views\Dashboard\Module\CreateModule.vue END

  // src\views\Dashboard\Module\Main.vue

  module_title: "Module title",
  editor: "Editor",
  sub_content: "Sub content",
  analytics: "Analytics",
  discard_changes: "Discard changes",
  current_version: "Current version",
  media: "Media",
  success: "Success",
  danger: "Danger",
  version_of: "Version of",
  approve: "Approve",
  go_to_the_original: "Go to the original",
  add_note: "Add note",
  start_review: "Start review",
  approve_review: "Approve review",

  // src\views\Dashboard\Module\Main.vue END

  // src\views\Dashboard\Module\Media.vue

  deleted: "Deleted",
  upload_audio: "Upload audio",
  upload_video: "Upload video",
  select_audio: "Select audio",
  select_video: "Select video",
  video: "Video",

  // src\views\Dashboard\Module\Media.vue END

  // src\views\Dashboard\Module\Overview.vue
  // src\views\Dashboard\Module\Overview.vue END

  // src\views\Dashboard\Module\RequestAuthor.vue

  request_author_to_module: "Request author to module",
  content_users_that_responded_to_your_request:
    "Content users that responded to your request",
  no_user_responded_to_your_request: "No user responded to your request",
  add_to_module: "Add to module",
  author_has_been_added: "Author has been added",

  // src\views\Dashboard\Module\RequestAuthor.vue END

  // src\views\Dashboard\Module\Settings.vue

  color_title: "color title",
  bookmark: "Bookmark",
  bookmarks: "Bookmarks",
  in_construction: "In construction",
  number: "Number",
  request_approval: "Request Approval",
  location_content_is_used: "Location content is used",
  card_color: "Card color",
  prefix: "Prefix",

  // src\views\Dashboard\Module\Settings.vue END

  // src\views\Dashboard\Module\SubContent.vue

  definitions: "Definitions",
  cards: "Cards",

  // src\views\Dashboard\Module\SubContent.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Advanced.vue
  // src\views\Dashboard\MonetizationBundle\Settings\Advanced.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Basic.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Settings\Basic.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Pricing.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Settings\Pricing.vue END

  // src\views\Dashboard\MonetizationBundle\Settings\Settings.vue

  settings: "Settings",
  pricing: "Pricing",
  content: "Content",
  banner: "Banner",
  overview: "Overview",
  checkout: "Checkout",
  archived: "Archived",
  status: "Status:",
  coming_soon: "Coming soon",
  beta: "Beta",
  live: "Live",

  // src\views\Dashboard\MonetizationBundle\Settings\Settings.vue END

  // src\views\Dashboard\MonetizationBundle\Analytics.vue

  // (static demo content)

  // src\views\Dashboard\MonetizationBundle\Analytics.vue END

  // src\views\Dashboard\MonetizationBundle\Main.vue

  basic: "Basic",
  monetization_bundle_title: "Monetization bundle title",

  // src\views\Dashboard\MonetizationBundle\Main.vue END

  // src\views\Dashboard\MonetizationBundle\Overview.vue
  // ???
  // src\views\Dashboard\MonetizationBundle\Overview.vue END

  // src\views\Dashboard\Page\Overview\CreateNew.vue

  create_new_page: "Create new page",
  page_added: "Page added",

  // src\views\Dashboard\Page\Overview\CreateNew.vue END

  // src\views\Dashboard\Page\Overview\Page.vue

  new_page: "New page",
  nothing_found: "Nothing found!",
  pages: "Pages",

  // src\views\Dashboard\Page\Overview\Page.vue END

  // src\views\Dashboard\Page\Single\GeneralSettings.vue

  page: "Page",

  // src\views\Dashboard\Page\Single\GeneralSettings.vue END

  // src\views\Dashboard\Page\Single\Page.vue

  active_page: "Active page",
  template: "Template",
  are_you_sure_you_want_to_leave_page:
    "Are you sure that want to leave the page? All unsaved changes will be lost.",

  // src\views\Dashboard\Page\Single\Page.vue END

  // src\views\Dashboard\Settings\Archive.vue

  archive: "Archive",
  you_can_select_archive_type:
    "You can select archive type, and you can choose whether remove permanently which means there will not be ability to restore, or restore from archive.",
  name: "Name",
  group: "Group",
  last_action: "Last action",
  actions: "Actions",
  restore: "Restore",
  delete: "Delete",
  are_you_sure_you_want_delete: "Are you sure you want delete",
  restored: "restored",
  module: "Module",
  no_archive_data: "No archive data",

  // src\views\Dashboard\Settings\Archive.vue END

  // src\views\Dashboard\Users\CreateUser.vue
  changePassword: "Change Password",
  create_new_user: "Create new user",
  email: "Email",
  initial_password: "Initial password",
  confirm_password: "Confirm password",
  default_language: "Default language",
  create_user: "Create user",
  user_has_been_registered: "User has been registered",
  country: "Country",
  select_roles_for_user: "Select roles for user",
  author: "Author",
  reviewer: "Reviewer",
  web: "Web",
  system: "System",
  super: "Super",
  creator: "Creator",
  linker: "Linker",

  // src\views\Dashboard\Users\CreateUser.vue END

  // src\views\Dashboard\Users\EditUser.vue

  edit_user: "Edit user",
  username: "Username",
  first_name: "First name",
  middle_name: "Middle name",
  last_name: "Last name",
  state: "State",
  street: "Street",
  zip: "Zip code",
  phones_list: "Phones list",
  phone: "Phone",
  remove: "Remove",
  enter_phone_number: "Enter desired phone number...",
  user_updated: "User has been updated",

  // src\views\Dashboard\Users\EditUser.vue END

  // src\views\Dashboard\Users\GeneratePayout.vue

  generate_payout_table: "Generate payout table",

  // src\views\Dashboard\Users\GeneratePayout.vue END

  // src\views\Dashboard\Users\Overview.vue

  new: "New",
  payout_report: "Payout report",
  permissions: "Permissions",
  activate: "Activate",
  deactivate: "Deactivate",
  verify: "Verify",

  // src\views\Dashboard\Users\Overview.vue END

  // src\views\Dashboard\ContractsSign.vue

  you_need_sign_contracts: "You need sign contracts",
  date: "Date",
  sign: "Sign",

  // src\views\Dashboard\ContractsSign.vue END

  // src\views\Logon\Login\Register.vue

  // i_agree_with_the_terms_of_use: () => '',
  // i_agree_to_the_privacy_policy: () => '',

  // src\views\Logon\Login\Register.vue END

  // src\views\Logon\Login\Signin.vue
  // src\views\Logon\Login\Signin.vue END

  // src\views\404.vue

  errorCode: "404",
  legal_vacuum: "legal vacuum",

  // src\views\404.vue END

  // src\views\Client.vue
  // src\views\Client.vue END

  // src\views\ComponentsTest.vue

  // src\views\ComponentsTest.vue END

  // src\views\Dashboard.vue

  role_mode: "Role mode",
  do_you_really_want_to_leave:
    "Do you really want to leave? you have unsaved changes!",

  // src\views\Dashboard.vue END

  // src\views\Dashboard\Module\AddAuthor.vue

  manage_authors: "Manage authors",
  remove_author: "Remove",
  author_deleted: "Author removed",
  linked_authors: "Linked authors",
  available_authors: "Available authors",
  author_added: "Author added",

  // src\views\Dashboard\Module\AddAuthor.vue END

  // src\views\Dashboard\Module\Editor\Search.vue
  // src\views\Dashboard\Module\Editor\Search.vue END

  // src\views\Dashboard\Module\Editor\RightSideBar.vue
  // src\views\Dashboard\Module\Editor\RightSideBar.vue END

  // src\views\Dashboard\Module\Editor\Request.vue
  in_review: "in review",
  review_requested: "review requested",
  review_status_sub: "sub modules",
  request_review: "Request review",
  request_review_text:
    "When submitting your written content for review one of the reviewer assigned to your module will see the new version and can make remarks. If everything is fine the reviewer will publish the content and thus it will become the newly available version. \n\nIf you have made changes to the nested sub modules of this module you can hand those in too by checking the checkbox below.",
  include_sub_module: "Include sub module",

  // src\views\Dashboard\Module\Editor\Request.vue END
  // src/views/Account/Bookmarks/Bookmark.vue
  update: "Update",
  // src/views/Account/Bookmarks/Bookmark.vue END
  // src/views/Account/Bookmarks/Create.vue
  create_bookmark: "Create bookmark",
  // src/views/Account/Bookmarks/Create.vue END
  // src/views/Account/Bundles/Modules/Remark/Create.vue
  note: "Note",
  create_remark: "Create remark",
  remark_added: "Remark has been added",
  // src/views/Account/Bundles/Modules/Remark/Create.vue END
  // src/views/Account/Bundles/Modules/Remark/Remark.vue
  edit_note_for: "Edit note for",
  // src/views/Account/Bundles/Modules/Remark/Remark.vue END
  // src/views/Account/Bundles/Modules/Module.vue
  remarks: "Remarks",
  last_update: "Last update",
  chapter: "Chapter",
  part_of: "Part of:",
  go_right: "Go right",
  go_left: "Go left",
  definitions_coach: "Definitions coach",
  no_remarks: "No remarks",
  definition_coach: "Definition coach",
  card_training: "Card training",
  last_updated: "Last updated",
  author_unknown: "Author unknown",
  // src/views/Account/Bundles/Modules/Module.vue END

  // src/views/Account/Bundles/Bundle.vue
  // src/views/Account/Bundles/Bundle.vue END

  // src/views/Account/Common/NewsletterSubscriptionPopup.vue
  confirm: "Confirm",
  // src/views/Account/Common/NewsletterSubscriptionPopup.vue END
  // src/views/Account/Common/Notes.vue
  // src/views/Account/Common/Notes.vue END
  // src/views/Account/Common/RemarkPopup.vue
  add_for: "Add for",
  // src/views/Account/Common/RemarkPopup.vue END
  // src/views/Account/Common/SubscriptionPopup.vue
  click_here_for_details: "Click here for details",
  // src/views/Account/Common/SubscriptionPopup.vue END
  // src/views/Account/Definitions/Definition.vue
  definitions_text: "... definitions text",
  reveal: "Reveal",
  next: "Next",
  // src/views/Account/Definitions/Definition.vue END
  // src/views/Account/Modules/Module/_helperSubPackage.vue
  themes: "Themes",
  back_to: "Back to: GS 4.1: Introduction to Criminal Law Dogmatics",
  next_chapter: "Next chapter",
  // src/views/Account/Modules/Module/_helperSubPackage.vue END
  // src/views/Account/Modules/Main.vue
  preparing_for_the_section_process: "Preparing for the section process",
  note_title: "Note title",
  note_content: "Note content",
  note_title_placeholder: "Note title...",
  note_content_placeholder: "Note content...",
  // src/views/Account/Modules/Main.vue END
  // src/views/Account/Modules/ModuleOverview.vue
  login_register: "Login/Register",
  module_overview: "Module overview",
  // src/views/Account/Modules/ModuleOverview.vue END
  // src/views/Account/Bookmarks.vue
  please_authorize_to_watch_the_content:
    "Please authorize to watch the content",
  no_bookmarks_added: "No bookmarks added",
  // src/views/Account/Bookmarks.vue END
  // src/views/Account/Bundles.vue
  bundles: "Bundles",
  // src/views/Account/Bundles.vue END
  // src/views/Account/Profile.vue
  user_account: "User account",
  download_user_data: "Download user data",
  to_update: "To update",
  deleting_an_account: "Deleting an account",
  change_image: "Change Image",
  male: "Male",
  female: "Female",
  various: "Various",
  birthday: "Birthday",
  profile_picture: "Profile picture",
  security: "Security",
  gender: "Gender",
  email_address: "Email-address",
  old_password: "Old password",
  new_password: "New password",
  repeat_the_new_password: "Repeat the new password",
  address: "Address",
  street_name_house_number: "Street name / House number",
  city: "City",
  postal_code: "Postal code",
  // src/views/Account/Profile.vue END
  // src/views/Dashboard/Affiliate/Overview.vue
  create_new_affiliate_link: "Create new affiliate link",
  affiliate_links_overview: "Affiliate links overview",
  // src/views/Dashboard/Affiliate/Overview.vue END
  // src/views/Dashboard/Card/Main.vue
  question: "Question",
  answer: "Answer",
  card_title: "Card title",
  related_package: "Related package",
  // src/views/Dashboard/Card/Main.vue END
  // src/views/Dashboard/Common/BlocksEditor.vue
  available_slots: " Available Slots :",
  // src/views/Dashboard/Common/BlocksEditor.vue END
  // src/views/Dashboard/Common/Container.vue
  alt_text: "Alt text",
  copy_link: "Copy link",
  // src/views/Dashboard/Common/Container.vue END
  // src/views/Dashboard/Common/CreateEntity.vue
  field: "Field",
  // src/views/Dashboard/Common/CreateEntity.vue END
  // src/views/Dashboard/Common/PopupSelect.vue
  search: "Search",
  not_set: "Not set",
  // src/views/Dashboard/Common/PopupSelect.vue END
  // src/views/Dashboard/Module/Editor/Editor.vue
  revert: "Revert",
  new_sub_module: "New sub module",
  definition: "Definition",
  create_new_card: "Create new card",
  create_new_definition: "Create new definition",
  paste_definition: "Paste Definition",
  select_definition: "Select definition",
  copying_to_clipboard_successful: "Copying to clipboard was successful",
  color_field: "Color field",
  image: "Image",
  upload_image: "Upload image",
  select_image: "Select image",
  cancel_review: "Cancel review",
  review_in_progress: "Review in progress",
  // src/views/Dashboard/Module/Editor/Editor.vue END
  // src/views/Dashboard/Module/Editor/History.vue
  history: "History",
  display_selected_version: "Display selected version",
  // src/views/Dashboard/Module/Editor/History.vue END

  //Months

  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",

  // Footer

  part_of_system: "This page is part of the recht-einfach.schule website",
  t_and_c: "T&C",
  privacy: "Datenschutz",
  inprint: "Impressum",

  // Common
  home: "Home",
  no_comments_so_far: "No comments so far",
  affiliate_link: "Affiliate link",
  card: "Card",
  contract: "Contract",
  incorrect_name: "Incorrect name",
  no_messages: "No messages",
  add_to_menu: "Add to menu",
  additional_optional_information: "Additional optional information",
  unpublished_message: "Unpublished message",
  published_message: "Published message",
  added_note: "Added note",
  deleted_note: "Deleted note",
  updated_note: "Updated note",

  invitation_to_join: "Invitation to join",
  as_an_author: "as an author",
  signed: "Signed",
  preview: "Preview",
  select_subscription: "Select subscription",
  per_month: "Per Month",
  deactivate: "Deactivate",
  activate: "Activate",
  generate: "Generate",
  add_content_bullet_point:"Add content bullet point",
  max_word_count: "Max word count",
  generate_content: "Generate content",
  tokens: "token(s)",
  payout_report: "Payout report",
  budget: "Budget",
  content_type: "Content type",
  weight_for: "Weight for",
  from: "From",
  to: "To",
  select_json_with_users: "Select json file with users",
  import: "Import",
  table: "Table",
  logo: "Logo",
  links: "Links",
  text_of_link: "Link text",
  url: "URL",
  modules_order: "Modules order",
  promises: "Promises",
  target_audience: "Target audience",
  providers: "Providers",
  reviews: "Reviews",
  create_new_space: "Create new space",
  space: "Space",
  spaces: "Spaces",
  faq: "FAQ",
  answer: "Answer",
  add_item: "Add item",
  remove_item: "Remove item",
  photo: "Photo",
  slug: "Slug",
  background_image: "Background image",
  exam: "Exam",
  exams: "Exams", 
  answers: "Answers",
  multiple_choice_test: "Multiple choice test",
  read_content: "Read content",
  open_test: "Open test",
  count_exam_as_passed_when_user_finished_reading_the_content: "Count exam as passed when user finished reading the content",
  create_exam_description_text: "Create exam with questions and variants of answers",
  open_test: "Open test",
  exam_of_module: "Exam of module",
  visit: "Visit",
  questions: "Questions",
  question: "Question",
  answer: "Answer",
  add_new_question: "Add new question",
  add_new_answer: "Add new answer",
  is_correct: "Is correct",
  required_amount_of_correct_answers_to_pass: "Required amount of correct answers to pass",
  submit_choice: "Submit choice",
  close_test: "Close test",
  back_to_exam_settings: "Back to exam settings",
  no_exam_for_this_module: "This module does not contain any exam, you will be redirected in 5 seconds...",
  next_question: "Next question",
  correctly_answered: "Answered correctly",
  total_questions: "Total questions",
  text_after_exam_fail: "Text after exam is failed",
  text_after_exam_succeeded: "Text after exam passed",
  required_amount_for_questions_is_zero: "Value for correct answers should be bigger or equal to 0",
  required_amount_of_correct_questions_exceeds_questions_amount: "Required amount of correct answers can't be more than total questions amount",
  assigned_to_space: "Assigned to space",
  exam_participants: "Exam participants",
  score: "Score",
  manage_owners: "Manager owners",
  available_admins: "Available admins",
  current_owners: "Current owners",
  owner_added: "Owner added",
  owner_removed: "Owner removed",
  owners: "Owners",
  space_editor: "Space editor",
  select_image: "Select image",
  subscribe: "Subscribe",
  free: "Free",
  start_course: "Start course",
  avatar: "Avatar",
  role: "Role",
  primary_author: "Primary author",
  css: "css",
  sass: "sass",
  sass_css: "SASS / CSS",
  sass_css_style_editor: "Sass / CSS style editor",
  pay: "Pay",
  monthly: 'Monthly',
  yearly: 'Yearly',
  one_time: 'One-time',
  i_agree_tos: 'I agree with <a href="/terms-of-service" target="_blank">Terms & Conditions</a>',
  tos_text: 'Phasellus ultrices tortor eget ante sagittis sagittis. Nunc et ligula at orci dapibus gravida. Maecenas purus libero, gravida nec ultrices gravida, sollicitudin quis felis. Aenean pulvinar massa quam, non blandit magna imperdiet non. Etiam consectetur urna eu erat fermentum commodo. Duis dignissim elementum turpis, eget blandit est euismod a.',
  recurring: 'Recurring',
  select_billing: 'Select billing',
  price: 'Price',
  blocks: 'Blocks',
  select_module: 'Select module',
  search: 'Search',
  links_changer: 'Links changer',
  original_link: 'Original link',
  replaced_link: 'Replaced link',
  link_text: 'Link text',
  or: 'or'
};
