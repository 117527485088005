import Vue from 'vue'

import jQuery from "jquery";
Vue.prototype.$ = jQuery;
Vue.prototype.jQuery = jQuery;

import prepareForm from '@/services/prepare-formdata';
Vue.prototype.$prepareForm = prepareForm;

Vue.prototype.$capitalize = ( value ) => value.charAt(0).toUpperCase() + value.slice(1);

Vue.prototype.$isObject = ( value ) => {
  if ( value && value !== null && typeof value === 'object' && !Array.isArray(value) ) return true;
  return false;
};

Vue.prototype.$defaultValue = ( kind ) => {
  let value = '';

  switch (kind) {
    case 'text':
    case 'textarea':
    case 'richtext':
    case 'image':
      value = '';
      break;
    case 'number':
      value = 0;
      break;
    case 'list':
      value = [];
      break;
    case 'object':
      value = {};
      break;
    case 'checkbox':
      value = false;
      break;
    case 'id':
      value = Date.now()
      break;
  }

  return value;
}

Vue.prototype.$urlSpaceSlug = ( url ) => {
  const splitedUrl = url.split('@');

  if (splitedUrl.length == 2) return splitedUrl[1];

  return '';
}

import countChanges from "@/services/count-changes";
Vue.prototype.$countChanges = countChanges;

import { sortUp } from '@/services/sorting';
import { sortDown } from '@/services/sorting';
Vue.prototype.$sortUp = sortUp;
Vue.prototype.$sortDown = sortDown;