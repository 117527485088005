'use strict';

import store from '@/store'

export const checkAuthentication = router => {
    router.beforeEach((to, from, next) => {
        let user = store.getters['user/get'];
        if (to.matched.some(record => record.meta.restricted)) {
            if(user.isAuthenticated){
                console.log(to, user)
                if(to.meta.role == user.role) next();
                else next({name: user.baseUrl()})
            }else  next({name:'login'})
        } else {
            if(user.isAuthenticated) next({name: user.baseUrl()})
            else next()
        }
    })
}



export const buildRouterTree = (tree, meta) => {
    for (let i = 0; i < tree.length; i++) {
        let submeta = meta;
        if (meta) {
            if (!tree[i].meta) tree[i].meta = meta;
            else {
                for (const key in meta) {
                    if (Object.hasOwnProperty.call(meta, key)) {
                        if (tree[i].meta[key] === undefined) tree[i].meta[key] = meta[key];
                    }
                }
            }
        }
        submeta = tree[i].meta;
        if (tree[i].children && tree[i].children.length > 0) buildRouterTree(tree[i].children, submeta)
    }
}