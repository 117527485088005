export default {
    updateMonetizationBundleList: ( state, data ) => state.monetizationBundleList = data,

    updateSingleMBData: ( state, payload ) => state.singleData = { ...state.localMBData, ...payload },

    updateLocalMBData: ( state, payload ) => {
        let copy = JSON.parse(JSON.stringify(payload))
        for ( let prop in payload ) {
            if ( [
                'title',
                'description',
                'groups',
                'content',
                'banner',
                'overview',
                'checkout',
                'status',
                'requestDone',
                'authors',
                'promises',
                'targetAudience',
                'providers',
                'pricing',
                'stripeId'
            ].includes(prop) ) {
                state.localMBData[prop] = copy[prop];
            }
        }
    },

    updateLocalMBDataString: ( state, payload ) => {
        if (payload.key.includes('.')) {
            // Should be updated later with nice recursive fn to update obj with just like 'key1.key2.key3.etc....'
           const [keyOne, keyTwo] = payload.key.split('.');

           if (!state.localMBData[keyOne]) state.localMBData[keyOne] = {};

            state.localMBData[keyOne][keyTwo] = payload.value;
        } else {
            state.localMBData[payload.key] = payload.value;
        }
    },

    updateLocalMBDataObject: ( state, payload ) => state.localMBData[payload.key_0][payload.key_1] = payload.value,
}