<template>
  <div
    :class="`formulate-input-element drop-down formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <h6>{{ title }}</h6>
    <div :id="context.attributes.id">
      <div
        class="drop-down__header"
        :class="{ active: openSelectInput }"
        @click="[loadData(), getPosition()]"
      >
        <span v-if="selected">{{ selected }}</span>
        <span v-else>display referenced value</span>
        <font-awesome-icon :icon="'caret-down'" />
      </div>
      <div
        class="drop-down__body body"
        :id="`${context.attributes.id}__body`"
        :class="{ active: openSelectInput }"
      >
        <div class="body__selected" v-if="!activeInput">{{ selected }}</div>
        <div class="body__selected" v-if="activeInput">
          <FormulateInput
            type="text"
            name="url"
            v-model="selected"
            placeholder="URL"
            @keyup="updateValue(selected)"
          />
        </div>
        <div class="body__search mt-3 position-relative">
          <FormulateForm
            @keyup="
              context.attributes.searchAction(search, $event, dropdownKey)
            "
            class="position-relative"
          >
            <FormulateInput
              type="text"
              name="search"
              v-model="search"
              placeholder="Search"
              @keyup="
                context.attributes.searchAction(search, $event, dropdownKey)
              "
            />
          </FormulateForm>
        </div>
        <div class="body__list">
          <div
            class="list"
            style="color: #a8a8a8; cursor: pointer"
            @mouseover="mouseOver"
            onmouseout="this.style.color='#a8a8a8';"
            v-for="(item, index) in searchQuery"
            :key="index"
            @click="setItem(item)"
          >
            {{ item.title }}
          </div>

          <div
            class="body__list_empty"
            v-if="emptyListText && !searchQuery.length"
          >
            {{ emptyListText }}
          </div>
        </div>

        <button
          class="mt-4"
          v-show="!disabled"
          variant="outline-primary"
          @click="loadMore()"
        >
          Load more
        </button>

        <button
          class="mt-4"
          v-if="removeAction && selected"
          variant="danger"
          @click="removeActionFN"
        >
          Remove selected
        </button>

        <button
          class="mt-4"
          v-if="setRootAction && !setRootBtnDisabled"
          variant="success"
          @click="setRootActionFN"
        >
          Set to root
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";

library.add(faCaretDown, faSearch);

export default {
  name: "DropDown",
  data() {
    return {
      openSelectInput: false,
      selected: "",
      search: "",
      page: 1,
    };
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    searchQuery() {
      return this.context.attributes.dataArray.filter((item) => {
        if (item.title) {
          return item.title.match(this.search);
        }
      });
    },
    activeInput() {
      if (this.context.attributes.activeInput) {
        return this.context.attributes.activeInput;
      } else {
        return false;
      }
    },
    disabled() {
      return this.context.attributes.disabled;
    },
    setRootBtnDisabled() {
      return this.context.attributes.setRootBtnDisabled;
    },
    dataArray() {
      return this.context.attributes.dataArray;
    },
    title() {
      return this.context.attributes.title;
    },
    emptyListText() {
      return this.context.attributes.emptyListText;
    },
    dropdownKey() {
      return this.context.attributes.dropdownKey;
    },
    selectedData: {
      get() {
        if (this.context.attributes.selected) {
          return this.context.attributes.selected;
        }

        return "";
      },
      set(select) {
        this.selected = select;
      },
    },
    removeAction() {
      return this.context.attributes.removeAction;
    },
    setRootAction() {
      return this.context.attributes.setRootAction;
    },
  },
  methods: {
    mouseOver(event) {
      if (!this.setRootBtnDisabled) {
        event.target.style.color = "#007bff";
      }
    },
    removeActionFN() {
      this.selectedData = "";
      this.removeAction();
    },
    setRootActionFN() {
      this.setRootAction();
      this.selectedData = "root";
    },
    loadData() {
      if (!this.openSelectInput) {
        this.context.attributes
          .getList({ page: 1, key: this.dropdownKey })
          .then(() => {
            this.selected = this.selectedData;
          })
          .catch((err) => console.log(err));
      }

      this.openSelectInput = !this.openSelectInput;
    },
    loadMore() {
      this.page += 1;
      this.context.attributes.getList({
        page: this.page,
        key: this.dropdownKey,
      });
    },
    setItem(item) {
      this.selected = item.permalink;
      this.updateValue(item.permalink);
    },
    updateValue(value) {
      let item = { url: value };
      if (this.context.attributes.parentBlockId) {
        item.parent_block_id = this.context.attributes.parentBlockId;
        item.field = "url";
      }
      this.$emit("dropdown-item-changed", item);
    },
    getPosition() {
      let getPosition = () => {
        if (
          !document.getElementById(this.context.attributes.id) &&
          !document.getElementById(`${this.context.attributes.id}__body`)
        )
          return;

        clearInterval(getPositionInterval);

        let dropdown_header = document.getElementById(
          this.context.attributes.id
        );

        let dropdown_body = document.getElementById(
          `${this.context.attributes.id}__body`
        );

        let app = document.getElementById("app");
        app.appendChild(dropdown_body);
        dropdown_body.style.left =
          dropdown_header.getBoundingClientRect().left + "px";
        dropdown_body.style.width = dropdown_header.clientWidth + "px";

        let bottomDifference =
          document.body.getBoundingClientRect().bottom -
          dropdown_header.getBoundingClientRect().bottom;
        let topDifference = dropdown_header.getBoundingClientRect().top;

        let height = 500;

        if (bottomDifference < topDifference) {
          if (topDifference < 500) {
            height = topDifference;
          }
          dropdown_body.style.height = height + "px";
          dropdown_body.style.top =
            dropdown_header.getBoundingClientRect().top -
            height +
            window.pageYOffset +
            "px";
        } else {
          if (bottomDifference < 500) {
            height = bottomDifference;
          }
          dropdown_body.style.height = height + "px";
          dropdown_body.style.top =
            dropdown_header.getBoundingClientRect().bottom +
            window.pageYOffset +
            "px";
        }
      };
      let getPositionInterval = setInterval(getPosition, 100);
    },
  },

  mounted() {
    let self = this;

    this.getPosition();

    let dropdown_header = document.getElementById(this.context.attributes.id);
    let dropdown_body = document.getElementById(
      `${this.context.attributes.id}__body`
    );

    document.addEventListener("click", function (e) {
      if (
        !dropdown_header.contains(e.target) &&
        !dropdown_body.contains(e.target)
      ) {
        self.openSelectInput = false;
      }
    });

    dropdown_header.addEventListener("mouseover", function () {
      self.getPosition();
    });
  },
  watch: {
    selected(newVal) {
      if (newVal.title) {
        this.selectedData = newVal.title;
      } else {
        this.selectedData = newVal;
      }
    },
  },
  beforeDestroy() {
    let dropdown_body = document.getElementById(
      `${this.context.attributes.id}__body`
    );
    dropdown_body.remove();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.drop-down {
  position: relative;
  max-width: 20em;
  margin-bottom: 0.1em;
  margin-top: 0.5em;

  &::before {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #bdc3c7;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    border-radius: 0.3em;
    box-sizing: border-box;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    padding: 0.75em 1em 0.75em 0.75em;
    width: 100%;

    ::v-deep svg {
      color: #a8a8a8;
      transform: rotate(0deg);
      -webkit-transition-duration: 300ms;
      -moz-transition-duration: 300ms;
      -o-transition-duration: 300ms;
      transition-duration: 300ms;
    }

    span {
      margin-right: 10px;
      color: #a8a8a8;
    }

    &.active {
      border: 1px solid $focus_color;

      ::v-deep svg {
        transform: rotate(-180deg);
      }
    }
  }

  &__body,
  .body {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $white;
    padding: 0;
    border: 0px solid #bdc3c7;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    border-radius: 0.3em;
    box-sizing: border-box;
    max-height: 0;
    overflow: auto;
    z-index: 9999;
    -webkit-transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-duration: 100ms;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: silver;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    &__list {
      opacity: 0;
      -webkit-transition-duration: 100ms;
      -moz-transition-duration: 100ms;
      -o-transition-duration: 100ms;
      transition-duration: 100ms;
      cursor: pointer;
      color: #a8a8a8;

      &_empty {
        margin-top: 15px;
      }
    }

    &.active {
      padding: 0.75em 1em 0.75em 0.75em;
      border: 1px solid #bdc3c7;
      height: auto;
      max-height: 500px;

      .body__list {
        opacity: 1;
      }
    }
  }

  ::v-deep .body {
    &__selected {
      color: #a8a8a8;
    }

    &__search {
      position: relative;

      .icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #a8a8a8;
      }
    }
  }

  button {
    width: 100%;

    &:disabled {
      color: #a8a8a8;
      border: 1px solid #a8a8a8;

      &:hover {
        border: 1px solid #a8a8a8;
        background-color: transparent;
      }
    }
  }
}
</style>
