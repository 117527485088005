import { indexOf } from "lodash";
import state from "./state";

export default {

	toggleMenu: ( state, changeState ) => state.menuOpened = changeState,

	setPageHeaderData: ( state, payload ) => state.pageHeaderData = payload,

	clearPopupSelect: ( state ) => state.popupSelectIds = [],

	setPopupSelectTimer: ( state, payload ) => state.popupSelectTimer = payload,

	setContracts: ( state, payload ) => state.contracts = payload,

	setPopupSelectIds: ( state, id ) => {
		if ( !state.popupSelectIds.includes( id ) ) state.popupSelectIds.push( id );
	},

	setPopupSelectTitles: ( state, payload ) => {
		let titles = {};
		payload.forEach( item => {
			titles[item._id] = item.title;
		} );
		state.popupSelectTitles = titles;
	},

	setPopupSelectValue: ( state, payload ) => {
		if ( !payload.multiSelect ) state.popupSelectValue = {};
		// if ( payload.action === 'select' ) state.popupSelectValue[payload.data._id] = payload.data;
		// else if ( payload.action === 'deselect' ) delete state.popupSelectValue[payload.data._id];
		if ( payload.action === 'select' ) state.popupSelectValue = payload.data;
		else if ( payload.action === 'deselect' ) state.popupSelectValue = {};
	},

	setTablePagination: ( state, payload ) => state.tablePagination = payload,

	copyToClipboard: ( state, payload ) => state.clipboard = payload,

	clearClipboard: ( state ) => state.clipboard = null,

	setCountries: ( state, countries ) => state.countries = countries,

	setDashboardMenu: ( state, menu ) => state.dashboardMenu = menu,

	setAdminRoles: ( state, roles ) => state.admin_roles = roles,

	setBreadcrumbs: ( state, payload ) => state.breadcrumbs = payload,

	setUserId: ( state, payload ) => state.user_id = payload,

	setFeatures: ( state, payload ) => {
		state.features = payload
	},

	setBasicDataLoaded: ( state, payload ) => state.basicDataLoaded = payload,

	setAllowedMediaTypes: ( state, payload ) => state.allowedMediaTypes = payload,
	
	setMonetizationBundle: ( state, payload ) => state.monetizationBundle = payload,

	setStripe: ( state, payload ) => state.stripe = payload,

	setForAdvancedBlock: ( state, payload ) => state.advancedBlock = payload
}