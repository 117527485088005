import axios from 'axios';

export default {
    create: (state, data) => {
        return new Promise((done) => {
            axios.post("/card/create", data)
                .then((res) => done(res.data))
                .catch((err) => done(err))
        })
    },
    getList: (state, data) => {
        return new Promise((done) => {

            let urlParameter = "";
            if (data.search || data.titleId || data.excludeId || data.kind) {
                let urlParameterArray = [];
                if (data.search) urlParameterArray.push(`search=${data.search}`);
                if (data.excludeId) urlParameterArray.push(`excludeId=${data.excludeId}`);
                if (data.kind) urlParameterArray.push(`kind=${data.kind}`);
                if (data.cardsMap) urlParameterArray.push('cardsMap=1');
                urlParameter = `?${urlParameterArray.join("&")}`;
            }
            axios.get(`card/list/${data.page}${urlParameter}`, data)
                .then(res => {
                    if (data.kind == 1) state.commit('updateCardList', res.data.data.data);
                    if (data.kind == 1 && data.cardsMap) state.commit('setCardsMap', res.data.cardsMap);
                    done(res.data)
                })
                .catch((err) => done(err))
        })
    },
    getSingle: (state, data) => {
        return new Promise(done => {
            axios.get(`/card/single/${data.id}`)
                .then(res => {
                    state.commit('updateSingleCard', res.data.data);
                    done(res.data);
                })
                .catch(err => done(err))
        })
    },
    getPublicSingle: (state, data) => {
        return new Promise(done => {
            axios.get(`/account/definitions/${data.id}`, {
                    baseURL: '/api/r'
                })
                .then(res => {
                    state.commit('updateSingleCard', res.data.data);
                    done(res.data);
                })
                .catch(err => done(err))
        })
    },
    update: (state, data) => {
        return new Promise(done => {
            axios.post(`/card/update`, data)
                .then(res => done(res.data))
                .catch(err => done(err))
        })
    },
}