export default {
    updateCardList: ( state, payload ) => {
        state.cardList = payload;
    },

    setCardsMap: (state, payload) => {
        payload.forEach( item => state.cardsMap[item._id] = item );
    },

    updateSingleCard: ( state, payload ) => {
        let copy = JSON.parse(JSON.stringify(payload))
        // kind: 0 - card
        if ( payload.kind === 0 ) {
            state.stateCardData = payload;
            for ( let prop in payload ) {
                if ( ['title', 'content_0', 'content_1'].includes(prop) ) {
                    state.cardData[prop] = copy[prop];
                }
            }
        }
        // kind: 1 - definition
        if ( payload.kind === 1 ) {
            state.stateDefinitionData = payload;
            for ( let prop in payload ) {
                if ( ['title', 'content_0', 'content_1'].includes(prop) ) {
                    state.definitionData[prop] = copy[prop];
                }
            }
        }
    },

    updateCardData: ( state, payload ) => state.cardData[payload.key] = payload.value,

    clearCardData: state => state.cardData = { title: '', content_0: '', content_1: '' },

    updateDefinitionData: ( state, payload ) => state.definitionData[payload.key] = payload.value,

    clearDefinitionData: state => state.definitionData = { title: '', content_0: '', content_1: '' },
}