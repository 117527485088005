'use strict';

module.exports = async function(req, res) {
    let error = [];
    let msg = ['success']

    if (!req.body.id) error.push('id_required');
    if (!req.body.questions) error.push('questions_required');

    let m;
    if (error.length == 0) {
        try {
            m = await req.app.db.models.Module.findOne({
                _id: req.body.id
            }).lean();
            if (!m) error.push('no_match')
            else if (m.archived) error.push('archived')
        } catch (e) {
            console.log(e);
            error.push('db_error');
        }
    }

    // build basicUpdatePayload
    let updatePayload = {};
    
    if (!error.length) {
        updatePayload = {
            [`exam.questions`]: req.body.questions
        };

        if (req.body.correctAnswersToPass || req.body.correctAnswersToPass == 0) updatePayload['exam.correctAnswersToPass'] = parseInt(req.body.correctAnswersToPass);
        if (req.body.afterExamText) updatePayload['exam.afterExamText'] = req.body.afterExamText;
        if (req.body.afterSuccessExamText) updatePayload['exam.afterSuccessExamText'] = req.body.afterSuccessExamText;
    }

    if (!error.length) {
        try {
            await req.app.db.models.Module.updateOne({
                _id: req.body.id
            }, updatePayload)
        } catch (e) {
            console.log(e);
            error.push('error')
        }
    }

    if (!error.length) res.send({
        success: true,
        msg: msg
    });
    else res.status(417).send({
        success: false,
        error: error
    })
};