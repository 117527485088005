import axios from "axios";

export default {
    create: (state, data) => {
        return new Promise((done) => {
            axios.post("/space/create", data)
                .then((res) => done(res.data))
                .catch((err) => done(err));
        });
    },
    getSpacesList: ( state , data ) => {
        return new Promise( done => {
            let urlParameter = '';
            if ( data && (data.search || data.titleId || data.excludeId )){
                let urlParameterArray = [];
                if (data.search ) urlParameterArray.push(`search=${data.search}`)
                if (data.excludeId ) urlParameterArray.push(`excludeId=${data.excludeId}`)
                urlParameter = `?${urlParameterArray.join('&')}`
            }
            axios.get( `/space/list${urlParameter}` )
                .then(res => {
                    state.commit( 'updateSpaceList', res.data.data.data )
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSingle: ( state , data ) => {
        return new Promise( done => {
            axios.get( `/space/single/${data.id}` )
                .then(res => {
                    state.commit( 'updateSingleSpaceData', res.data.data );
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getAccountSpace: ( state ) => {
        return new Promise( done => {
            axios.get( `/account/get-space`, { baseURL: "/api/r" }, )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getAccountSpaceMbundles: ( state ) => {
        return new Promise( done => {
            axios.get( `/account/get-space-mbundles`, { baseURL: "/api/r" }, )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getAccountSpaceMbundle: ( state, id ) => {
        return new Promise( done => {
            axios.get( `/account/get-space-mbundle/${id}`, { baseURL: "/api/r" }, )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    updateSpace: ( state , data ) => {
        return new Promise( done => {
            axios.post( `/space/update`, data )
                .then(res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    addOwner: (context, data) => {
        return new Promise((done) => {
            axios
            .post(`/space/owner/add`, data)
            .then((res) => done(res.data))
            .catch((err) => done(err));
        });
    },
    removeOwner: (context, data) => {
        return new Promise((done) => {
            axios
            .post(`/space/owner/remove`, data)
            .then((res) => done(res.data))
            .catch((err) => done(err));
        });
    },
}