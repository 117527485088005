export default [
	{
		path: '/dashboard/spaces',
		name: 'spaces-overview',
		meta: {
			relation: 'spaces',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/Space/Overview.vue')
		},
		children: [
			{
				path: '/dashboard/space/create',
				name: 'create-space',
				meta: {
					relation: 'spaces',
				},
				components: {
					create_space: () => import('@/views/Dashboard/Common/CreateEntity.vue')
				},
			},
		]
	},
	{
		path: '/dashboard/space/:id',
		name: 'space-container',
		meta: {
			relation: 'spaces',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/Space/Main.vue')
		},
		children: [
			{
				path: '/dashboard/space/:id',
				name: 'space-single',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/Editor.vue')
				}
			},
			{
				path: '/dashboard/space/:id/reviews',
				name: 'space-reviews',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/Reviews.vue')
				}
			},
			{
				path: '/dashboard/space/:id/authors',
				name: 'space-authors',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/Authors.vue')
				}
			},
			{
				path: '/dashboard/space/:id/blocks',
				name: 'space-blocks',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/SpaceBlocks.vue')
				}
			},
			{
				path: '/dashboard/space/:id/owners',
				name: 'space-owners',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/Owners.vue')
				},
				children: [
					{
						path: '/dashboard/space/:id/owners/manage',
						name: 'space-owners-manage',
						meta: {
							relation: 'spaces'
						},
						components: {
							space_owners: () => import('@/views/Dashboard/Space/ManageOwnersPopup.vue')
						}
					}
				]
			},
			{
				path: '/dashboard/space/:id/css',
				name: 'space-css',
				meta: {
					relation: 'spaces',
				},
				components: {
					space_content: () => import('@/views/Dashboard/Space/SpaceCss.vue')
				}
			},
		]
	}
]