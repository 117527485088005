export default [
	{
		path: '/dashboard/monetization-bundle-overview',
		name: 'monetization-bundle-overview',
		meta: {
			relation: 'monetization-bundles',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/MonetizationBundle/Overview.vue')
		},
		children: [
			{
				path: '/dashboard/monetization-bundle-overview/create',
				name: 'create-monetization-bundle',
				meta: {
					relation: 'monetization-bundles',
				},
				components: {
					create_monetization_bundle: () => import('@/views/Dashboard/Common/CreateEntity.vue')
				},
			},
		]
	},
	{
		path: '/dashboard/monetization-bundle/:id',
		name: 'monetization-bundle',
		meta: {
			relation: 'monetization-bundles',
		},
		components: {
			dashboard: () => import('@/views/Dashboard/MonetizationBundle/Main.vue')
		},
		children: [
			{
				path: '/dashboard/monetization-bundle/:id/settings',
				name: 'monetization-bundle-settings',
				meta: {
					relation: 'monetization-bundles',
				},
				components: {
					monetization_bundle_content: () => import('@/views/Dashboard/MonetizationBundle/Settings/Settings.vue')
				},
				children: [
					{
						path: '/dashboard/monetization-bundle/:id/settings/basic',
						name: 'mb-settings-basic',
						meta: {
							relation: 'monetization-bundles',
						},
						components: {
							mb_settings: () => import('@/views/Dashboard/MonetizationBundle/Settings/Basic.vue')
						},
					},
					// {
					// 	path: '/dashboard/monetization-bundle/:id/settings/pricing',
					// 	name: 'mb-settings-pricing',
					// 	meta: {
					// 		relation: 'monetization-bundles',
					// 	},
					// 	components: {
					// 		mb_settings: () => import('@/views/Dashboard/MonetizationBundle/Settings/Pricing.vue')
					// 	},
					// },
					{
						path: '/dashboard/monetization-bundle/:id/settings/:kind',
						name: 'mb-settings-advanced',
						meta: {
							relation: 'monetization-bundles',
						},
						components: {
							mb_settings: () => import('@/views/Dashboard/MonetizationBundle/Settings/Advanced.vue')
						},
					},
					{
						path: '/dashboard/monetization-bundle/:id/authors',
						name: 'mb-settings-authors',
						meta: {
							relation: 'monetization-bundles',
						},
						components: {
							mb_settings: () => import('@/views/Dashboard/MonetizationBundle/Settings/Authors.vue')
						},
					}
				]
			},
			{
				path: '/dashboard/monetization-bundle/:id/analytics',
				name: 'monetization-bundle-analytics',
				meta: {
					relation: 'monetization-bundles',
				},
				components: {
					monetization_bundle_content: () => import('@/views/Dashboard/MonetizationBundle/Analytics.vue')
				},
			},
		]
	},
]