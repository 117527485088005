'use strict';

module.exports = async function(req, res) {
    let error = [];
    let msg = ['success']

    if (!req.body.id) error.push('id_required');
    if (!req.body.payload?.replacedLinks || typeof req.body.payload.replacedLinks != 'object') error.push('payload_required');

    let m;
    if (error.length == 0) {
        try {
            m = await req.app.db.models.Module.findOne({
                _id: req.body.id
            }).lean();
            if (!m) error.push('no_match')
            else if (m.archived) error.push('archived')
        } catch (e) {
            console.log(e);
            error.push('db_error');
        }
    }

    // build basicUpdatePayload
    let replacedLinks = {};

    if (!error.length && req.body.payload.replacedLinks) {        
        const originalLinks = Object.keys(req.body.payload.replacedLinks);
        for (let index = 0; index < originalLinks.length; index++) {
            const originalLink  = originalLinks[index];
            const replacedLink = req.body.payload.replacedLinks[originalLink];
            
            if (typeof originalLink != 'string' || typeof replacedLink != 'string') {
                error.push('incorrect_payload');
                break;
            }

            replacedLinks[originalLink] = replacedLink;
        }
    }

    if (!error.length) {
        try {
            await req.app.db.models.Module.updateOne({
                _id: req.body.id
            }, {
                replacedLinks: replacedLinks
            })
        } catch (e) {
            console.log(e);
            error.push('error')
        }
    }

    if (error.length == 0) res.send({
        success: true,
        msg: msg
    });
    else res.status(417).send({
        success: false,
        error: error
    })
};