import axios from "axios";
import saveExamQuestions from "../../../routes/module/exam/save-exam-questions";
import saveReplacedLinks from "../../../routes/module/save-replaced-links";

export default {
  create: (state, data) => {
    return new Promise((done) => {
      axios
        .post("/module/create", data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  getList: (state, data) => {
    return new Promise((done) => {
      let urlParameter = "";
      if (data.search || data.titleId || data.excludeId || data.parent) {
        let urlParameterArray = [];
        if (data.search) urlParameterArray.push(`search=${data.search}`);
        if (data.excludeId)
          urlParameterArray.push(`excludeId=${data.excludeId}`);
        if (data.parent) urlParameterArray.push(`parent=${data.parent}`);
        urlParameter = `?${urlParameterArray.join("&")}`;
      }
      axios
        .get(`/module/list/${data.page}${urlParameter}`)
        .then((res) => {
          state.commit("updateModulesList", res.data.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getSingle: (state, data) => {
    let urlParameter = "";
    if (data.state) {
      let urlParameterArray = [];
      if (data.state) urlParameterArray.push(`state=${data.state}`);
      urlParameter = `?${urlParameterArray.join("&")}`;
    }
    return new Promise((done) => {
      axios
        .get(`/module/single/${data.id}${urlParameter}`)
        .then((res) => {
          state.commit("updateSingleModule", res.data.data);
          state.commit("setContentResponseWaiting", true);

          if (res.data.data.content === null) {
            state.commit("updateModuleContent", []);
          } else if (res.data.data.content && res.data.data.content.content) {
            state.commit("updateModuleContent", res.data.data.content.content);
          }
          state.commit("refreshContentUpdateKey");
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getModuleContent: (context, kind) => {
    return new Promise((done) => {
      axios
        .get(`/content/list-${kind}`)
        .then((res) => {
          context.commit("updateModuleContent", res.data.data);
          done(res);
        })
        .catch((err) => done(err));
    });
  },
  getContentList: (state, data) => {
    return new Promise((done) => {
      axios
        .get(`/module/content/${data.monetization}/${data.parent}`)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  update: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/update`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  saveReplacedLinks: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/save-replaced-links`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  toggleAuthor: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/toggle-author`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  addAuthor: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/author/add`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  removeAuthor: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/author/remove`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  addReviewer: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/reviewer/add`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  removeReviewer: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/reviewer/remove`, data)
        .then((res) => done(res.data))
        .catch((err) => done(err));
    });
  },
  requestAuthor: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/author/request`, data)
        .then((res) => {
          // context.commit("updateSuggestedAuthors", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },

  addNote: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/note/add`, data)
        .then((res) => {
          done(res.data);
          context.commit("setContent", res.data.data);
        })
        .catch((err) => done(err));
    });
  },
  getNotesr: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/note/get`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  deleteNote: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/note/delete`, data)
        .then((res) => {
          context.commit("setContent", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  updateNote: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/note/update`, data)
        .then((res) => {
          context.commit("setContent", res.data.data);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  addMedia: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/media/add`, data)
        .then((res) => {
          if (res.data.success) {
            context.commit("setSingleDataMedia", res.data.data.media);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  deleteMedia: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/media/delete`, data)
        .then((res) => {
          if (res.data.success) {
            context.commit("setSingleDataMedia", res.data.data.media);
          }
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  sortSubModule: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/sort-sub-module`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getRequestAuthorsMessage: (state, data) => {
    return new Promise((done) => {
      axios
        .get(`/module/author/request/display/${data.id}`)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getContentHistory: (state, id) => {
    return new Promise((done) => {
      axios
        .get(`/module/editor/list-history/${id}`)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  getSingleHistory: (state, id) => {
    return new Promise((done) => {
      axios
        .get(`/module/editor/get-single-history/${id}`)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  requestReview: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/editor/request-review/${data.id}`, { sub: data.sub })
        .then((res) => {
          if (res.data.success) state.commit("setSingleDataReviewStatus", 1);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  rejectReview: (state, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/reject`, data)
        .then((res) => {
          if (res.data.success) state.commit("setSingleDataReviewStatus", 0);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  convertToCopy: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/editor/convert-to-copy`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  deleteHistory: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/editor/delete-history`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  reviewApprove: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/approve`, data)
        .then((res) => {
          if (res.data.success) context.commit("setSingleDataReviewStatus", 3);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  reviewStart: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/review/start`, data)
        .then((res) => {
          if (res.data.success) context.commit("setSingleDataReviewStatus", 2);
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  archive: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/archive`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  toggleExamType: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/toggle-exam-type`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  },
  saveExamQuestions: (context, data) => {
    return new Promise((done) => {
      axios
        .post(`/module/save-exam-questions`, data)
        .then((res) => {
          done(res.data);
        })
        .catch((err) => done(err));
    });
  }
};
