/**
    http://localhost:8080/account/spaces/@testik - /space (by this time user logged in to certain space)
    http://localhost:9091/account/space/dsgsd/bundle/65a4d9030ae9ee136065d79e/authors - /account/mb (space id will be available for backend because user has it when logged in)
    http://localhost:9091/account/space/dgswds/bundles - /account/mbs (space id is taken from user object)
    
 **/

export default [
    {
        path: "space",
        name: "account-space",
        meta: {
            relation: "spaces",
        },
        components: {
            client: () => import("@/views/Account/SpacesSSR/Space.vue")
        },
        children: [
            {
                path: "/",
                name: "account-space-home",
                meta: {
                    relation: "spaces"
                },
                components: {
                    space_content: () => import("@/views/Account/SpacesSSR/SpaceHome.vue")
                }
            },
            {
                path: "/courses",
                name: "account-space-courses",
                meta: {
                    relation: "spaces"
                },
                components: {
                    space_content: () => import("@/views/Account/SpacesSSR/SpaceBundles.vue")
                }
            },
            {
                path: "/authors",
                name: "account-space-authors",
                meta: {
                    relation: "spaces"
                },
                components: {
                    space_content: () => import("@/views/Account/SpacesSSR/SpaceAuthors.vue")
                }
            },
            {
                path: "/reviews",
                name: "account-space-reviews",
                meta: {
                    relation: "spaces"
                },
                components: {
                    space_content: () => import("@/views/Account/SpacesSSR/SpaceReviews.vue")
                }
            }
        ]
    },
    {
        path: "space/bundles",
        name: "account-space-bundles",
        meta: {
            relation: "space-bundles"
        },
        components: {
            client: () => import("@/views/Account/Spaces/ModulesOverviewOfBundle")
        }
    },
    {
        path: "space/bundle/:bundleId",
        name: "account-space-bundle",
        meta: {
            relation: "space-bundle"
        },
        components: {
            client: () => import("@/views/Account/Spaces/SpaceSingleMonetizationBundle")
        },
        children: [
            {
                path: "/",
                name: "account-space-mbundle",
                meta: {
                    relation: "space-bundle"
                },
                components: {
                    space_mbundle: () => import("@/views/Account/Spaces/SpaceSingleMonetizationBundle/SpaceSingleMbundleHome.vue")
                }
            },
            {
                path: "course",
                name: "account-space-mbundle-course",
                meta: {
                    relation: "space-bundle"
                },
                components: {
                    space_mbundle: () => import("@/views/Account/Spaces/SpaceSingleMonetizationBundle/SpaceSingleMbundleCourse.vue")
                }
            },
            {
                path: "details",
                name: "account-space-mbundle-details",
                meta: {
                    relation: "space-bundle"
                },
                components: {
                    space_mbundle: () => import("@/views/Account/Spaces/SpaceSingleMonetizationBundle/SpaceSingleMbundleDetails.vue")
                }
            },
            {
                path: "authors",
                name: "account-space-mbundle-authors",
                meta: {
                    relation: "space-bundle"
                },
                components: {
                    space_mbundle: () => import("@/views/Account/Spaces/SpaceSingleMonetizationBundle/SpaceSingleMbundleAuthors.vue")
                }
            }
        ]
    }
];
  